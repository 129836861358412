.Filtershow {
  color: grey;
  background-color: #131313;
  padding: 15px;
  border-radius: 8px;
  font-size: 20px;
  line-height: 20px;
}
.Filtershow svg {
  display: none;
  visibility: hidden;
}
.Filterhide {
  background-color: #292929;
  padding: 8px;
  color: white;
}
.Filterhide svg {
  margin: 12px;
}

.Filter hr {
  width: 100%;
}
.Hide {
  display: none;
  visibility: hidden;
}
.Drop {
  width: 100%;
  color: white;
  padding: 12px;
  background-color: #292929;
  outline-color: #292929;
}
