.Header {
  padding: 20px;
  background-color: #131313;
}

.Brand {
  font-size: 28px;
  font-weight: 800;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.User {
  font-size: 24px;
  font-weight: 800;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Headerimg {
  width: 37px;
  height: 37px;
}
